import { type MetaFunction, type LoaderFunctionArgs, json  } from '@remix-run/node'
import { useLoaderData, Link, useRouteLoaderData, NavLink} from '@remix-run/react'
import clsx from 'clsx'
import { FingerprintIcon, type LucideIcon, SparklesIcon, GlobeIcon, CheckIcon } from 'lucide-react'
import { useState, type CSSProperties, useRef, forwardRef, useEffect } from 'react'
//import Confetti from 'react-confetti' 
import { useInView } from 'react-intersection-observer';
import BajajImg from '#app/assets/bajaj.jpg'
import BajajFullImage from '#app/assets/bajajImg.png'
import GooglePlayImg from '#app/assets/googleplay3.png'
import InvestNowImg from '#app/assets/investNow.png'
import MahindraImg from '#app/assets/mahindra.jpg'
import MahindraFullImage from '#app/assets/mahindrafullimage.png'
import MobileStep1 from '#app/assets/mobileStep1.png'
import MobileStep2 from '#app/assets/mobileStep2.png'
import MobileStep3 from '#app/assets/mobileStep3.png'
import MobileStep4 from '#app/assets/mobileStep4.png'
import MobileStep5 from '#app/assets/mobileStep5.png'
import ShriramImg from '#app/assets/shriram.jpeg'
import ShriramFullImage from '#app/assets/shriramfullImage.svg'
import Step1 from '#app/assets/step1.png'
import Step2 from '#app/assets/step2.png'
import Step3 from '#app/assets/step3.png'
import Step4 from '#app/assets/step4.png'
import Step5 from '#app/assets/step5.png'
import SuperFDImg from '#app/assets/superFDCrop.png'
import SuryodayImg from '#app/assets/suryoday.png'
import SuryodayFullImage from '#app/assets/suryodayfullimage.png'
import UnityImg from '#app/assets/unity.jpg'
import UnityFullImage from '#app/assets/unityfullimage.svg'
import { FeatureCard } from '#app/components/hover-card'
import { ImageWindow } from '#app/components/ImageWindow.tsx'
import { AnimatedBeam } from '#app/components/magicui/animated-beam'
import CanvasConfetti, { type ConfettiRef } from "#app/components/magicui/confetti";
import TiltCard from '#app/components/magicui/TiltCard.tsx'
import { Accordion, AccordionContent, AccordionItem, BadgeAccordionTrigger } from '#app/components/ui/accordion'
import { LampContainer } from '#app/components/ui/animatation/lamp'
// import { Avatar, AvatarFallback, AvatarImage } from '#app/components/ui/avatar'
import { BentoCard, BentoGrid } from '#app/components/ui/bento-grid'
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '#app/components/ui/carousel'
import { Dialog, DialogClose, DialogContent } from '#app/components/ui/dialog'
import { PageContainer } from '#app/components/ui/layout'
// import { MultiStepLoader } from '#app/components/ui/multi-step-loader'
import { Separator } from '#app/components/ui/separator.tsx'
import { type loader as rootLoader } from '#app/root.tsx'
import { getUserId } from '#app/utils/auth.server.ts'
import { prisma } from '#app/utils/db.server'
import { cn, combineHeaders, getFdImgSrc } from '#app/utils/misc'
import { getMetaInfoForPages, getSocialMetas, HOME_PAGE } from '#app/utils/seo'
import { getSplash} from '#app/utils/splash.server'
import { Badge, OBadge } from "#components/ui/badge.tsx"
import { Button } from "#components/ui/button.tsx"
import { Card, CardContent, CardHeader} from "#components/ui/card.tsx"
import { getBookedFDStatusBadge, getMaxCharName, getSFBStatus } from '../users_+/bookedfd'


const steps = [
  { id: '1', name: 'Select FD 🏦' },
  { id: '2', name: 'KYC 📄' },
  { id: '3', name: 'Review ✅' },
  { id: '4', name: 'Payment 💳' },
  { id: '5', name: 'FD Receipt 🎉' },
];

const CheckFilled = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={cn("w-6 h-6 ", className)}
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
        clipRule="evenodd"
      />
    </svg>
  );
};


export const meta: MetaFunction = () => {
  const {title, description,url, keywords, image } = getMetaInfoForPages(HOME_PAGE)

  return [
    { viewport: 'width=device-width,initial-scale=1,viewport-fit=cover' },
		...getSocialMetas({
			keywords,
			url,
			image,
			title,
			description,
		}),
  ]
}

type TFd = {
  id: string;
  name: string;
  isActive: boolean;
  isRbiInsured: boolean;
  highestInterest: number;
  order: number;
  logoImage: {
    id: string;
    altText: string | null;
  } | null;
}

type WrapperStyle = CSSProperties & {
  '--bottom': string;
};

export async function loader({ request }: LoaderFunctionArgs){

  const userId = await getUserId(request)
  let recentUserFd = null

  if(userId){
    recentUserFd = await prisma.userFd.findFirst({
      where : { userId },
      select: {
          id: true,
          status: true,
          amount: true,
          tenure: true,
          fdName: true,
          fdImageId: true,
          interest: true,
          isRbiInsured: true,
          tenureId: true,
          tenureYearFormat: true,
          appId: true,
          schemeId: true,
          isDeleted: true,
          maturityDate: true,
          maturityAmount: true,
          fdrDate: true,
          bookedDate: true,
          createdAt: true,
          fdSession: {
              select: {
                  step: true,
                  status: true
              }
          }
      },
      orderBy: {
          createdAt: 'desc'
      }
    })
  }
  
  const { page, headers: splashHeaders } = await getSplash(request)

  const platformsFd = await prisma.platform.findMany({
    where: {
      isActive: true,
    },
    select: {
      id: true,
      name: true,
      isActive: true,
      
      providers: {
        where: {
          isActive: true,
        },
        select: {
          id: true,
          issuerId: true,
          issuerName: true,
          fd: {
            where: {
              isActive: true
            },
            select: {
              id: true,
              name: true,
              isRbiInsured: true,
              highestInterest: true,
              order: true,
              isActive: true,
              logoImage: {
                select: { id: true, altText: true}
              },
            }
          }
        }
      }
    }
  })

  const fdArray = [] as TFd[]
  platformsFd.map(platform => {
    platform.providers.map(provider => {
      if(provider.fd){
        fdArray.push(provider.fd)
      }
      return null
    })
    return null
  })

  // const fds = await prisma.fd.findMany({
  //   select: {
  //     id: true,
  //     name: true,
  //     isRbiInsured: true,
  //     highestInterest: true,
  //     order: true,
  //     isActive: true,
  //     logoImage: {
  //       select: { id: true, altText: true}
  //     },

  //     provider: {
         
  //     }

  //   },
  //   where: { isActive: true }
  // })

  return json({ fds: fdArray.sort((a: any, b: any) => a.order > b.order ? 1 : -1 ), additionalGain: page, recentUserFd }, {
    headers: combineHeaders(
      splashHeaders
    )
  })
}

export default function Index() {
  const data = useLoaderData<typeof loader>()
	const rootData = useRouteLoaderData<typeof rootLoader>('root')
  const [isSplash, setIsSplash] = useState<boolean>(data.additionalGain ?? false) // (data.additionalGain ?? false) when splash is Active
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const confettiRef = useRef<ConfettiRef>(null);
  const { currentNumber: step, setNumber } = useNumberCycler();

	return (
		// <PageBodyWithClassName className="pt-0">
      <PageContainer className="px-0">

      {
        isSplash ? (
          // <Confetti 
          //  width={500}
          //  height={300}
          //  numberOfPieces={200}
          //  recycle={false}
          //  style={{
          //   width: '100%',
          //   height: '100%',
          //   zIndex: 100
          //  }}
          //  />
          <div className="">
           {/* <CanvasConfetti
            ref={confettiRef}
            className="w-full absolute left-0 top-0 z-100 size-full"
            onMouseEnter={() => {
              confettiRef.current?.fire({});
            }}
          /> */}
          </div>
          
          
        ) : null
      }

      <Dialog open={isSplash} onOpenChange={setIsSplash}>
      <DialogContent className="sm:max-w-[425px] drop-shadow-sunlightGlow">
          <div className="py-4 flex flex-col items-center justify-center text-center space-y-8 drop-shadow-glow">
            <h1 className="text-[30px] font-semibold">
             With Super FD you can have an additional gain of
            </h1>
            <div>
            <span className="pl-2 text-[50px] font-extrabold text-green-500">
            &#8377; {new Intl.NumberFormat('en-In').format(Number(data.additionalGain ?? 50000))}
            </span>
            </div>
            <div className="text-[30px] font-semibold">every year</div>
            <div>
              <DialogClose asChild>
              <Button variant="default">
                Got it
              </Button>
              </DialogClose>
            </div>
        </div>
      </DialogContent>
    </Dialog>

      <LampContainer >
      <div className="pt-20 pb-24">
      <section id="heroSection" className="flex flex-col items-center justify-center text-center space-y-8">
        {/* <Badge variant="secondary">Introducing SuperFd</Badge> */}
        <Link to={ rootData && rootData.user ? "#joinTop1Percent" : '/auth'} className="data-te-smooth-scroll-init bg-slate-800 animate-slidein [--slidein-delay:300ms] opacity-0 no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-full p-px text-xs font-semibold leading-6 text-white inline-block">
          <span className="absolute inset-0 overflow-hidden rounded-full">
            <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </span>
          <div className="relative flex space-x-2 items-center z-10 rounded-full py-0.5 px-4 ring-1 ring-white/10">
            <span>
              Join the Top 1%
            </span>
            <svg
              fill="none"
              height="16"
              viewBox="0 0 24 24"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.75 8.75L14.25 12L10.75 15.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </div>
          <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40" />
        </Link>
        {/* <h1 className=" hidden md:block animate-slidein [--slidein-delay:300ms] opacity-0 text-[2.5rem] lg:text-6xl font-semibold">Earn <span className="text-gain">9.1%</span> p.a. interest rate 
         <br /> on your next FD
        </h1> */}
        <h1 className=" hidden md:block animate-slidein [--slidein-delay:300ms] opacity-0 text-[2.5rem] lg:text-6xl font-semibold">Earn <span className="text-gain">9.1%</span> p.a. on FDs
        </h1>
        <h1 className=" md:hidden animate-slidein [--slidein-delay:300ms] opacity-0 text-[1.75rem] lg:text-6xl font-semibold">
          Earn <span className="text-gain">9.1%</span> p.a. on FDs
        </h1>
        {/* <p className="animate-slidein [--slidein-delay:500ms] opacity-0 text-xl text-gray-400 max-w-2xl">
          Meet the new standard for booking fds. Streamline process of form filling, fds comparisons and no need to create different bank account.
        </p
        <div className="animate-slidein [--slidein-delay:700ms] opacity-0">
            <Button className="inline-flex animate-shimmer text-primary-foreground dark:text-primary items-center justify-center rounded-md border bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%]">
              Get Started
            </Button>
        </div> */}

        </section>
      </div>
      </LampContainer>
    
      {/* <div className="py-40">introducing_superFD
        <h1 className="text-[40px] font-semibold">
          Earn <span className="text-primary">9.85% interest rate</span>
          <br />
          on your next FD 
        </h1>
      </div> */}

      {
        data.recentUserFd && !data.recentUserFd.isDeleted && !(data.recentUserFd.status === 'active' || data.recentUserFd.status === 'under_review') && (
          <>
          {/* <div className="w-full md:mb-2 grid gap-4 md:gap-10 md:grid-cols-2 justify-items-center">
          <div 
                className="w-[calc(100vw-3rem)] sm:w-full md:min-w-[70%] max-w-md"
              >
          <div className="py-2 flex flex-col items-center justify-start text-left space-y-8">
              <h1 className="text-h6 md:text-[25px]">Complete your FD booking</h1>
          </div>
              </div>
          </div> */}
          
          <div id="recentUserFd" className="w-full mb-10 lg:mb-20 grid gap-2 md:grid-cols-1 justify-items-center">
          <div className="py-2 flex flex-col items-center justify-start text-left space-y-8">
              <h1 className="text-h6 md:text-[25px]">Complete your FD booking</h1>
          </div>

          <div 
                className="w-[calc(100vw-3rem)] sm:w-full md:min-w-[40%] max-w-md flex justify-center"
              >
                <NavLink
                  key={data.recentUserFd.id}
                  to={`users/bookedfd/${data.recentUserFd.id}`}
                  preventScrollReset
                  prefetch="intent"
                  className={({ isActive }) =>
                      cn('w-full md:min-w-[70%] max-w-md block rounded-xl border-2 bg-card text-card-foreground shadow text-base lg:text-xl hover:bg-accent', isActive && 'bg-accent')
                  }
                >
                  <Card 
                  className="w-full md:min-w-[70%] max-w-md border-0"
                  >
                    <CardHeader className="relative p-6 xs:p-6 lg:static space-y-1 justify-between w-full flex-row">
                      <div className="flex space-x-2 md:space-x-4 align-center">
                          <div className="p-2 border shadow-sm rounded-xl">
                              <img
                              src={getFdImgSrc(data.recentUserFd.fdImageId)}
                              alt={data.recentUserFd.fdName}
                              className="h-8 w-8 xs:h-12 xs:w-12 object-cover rounded-full"
                              />
                          </div>
                          <div className="px-1 md:px-2 py-1 lg:px-2 self-center">
                              <div>{getMaxCharName(data.recentUserFd.fdName, 22)}</div>
                              <p className="text-xs text-muted-foreground">
                                  {new Date(data.recentUserFd.createdAt).toLocaleString('en-In', {
                                      timeZone: 'Asia/Kolkata',
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric"
                                  })}
                              </p>
                          </div>
                      </div>

                      <div className="md:hidden self-end absolute right-0 top-0.5 rounded-none">
                        {/* <Badge variant={"fd_pending"} className="text-xs"> */}
                        {
                        // data.recentUserFd.status === 'session-started'
                        true
                          ? (
                            <Badge variant="fd_pending_small" className="text-xs">{getSFBStatus(data.recentUserFd.fdSession?.step, data.recentUserFd.fdSession?.status) }</Badge>
                          )
                          : null //getBookedFDStatusBadge(data.recentUserFd.status)
                        }

                        {/* </Badge> */}
                      </div>
              
                      <div className="self-center hidden md:block">
                      {
                        data.recentUserFd.status === 'session-started'
                        ? (
                          <Badge variant="fd_pending" className="text-xs px-2 lg:px-3">{getSFBStatus(data.recentUserFd.fdSession?.step, data.recentUserFd.fdSession?.status) }</Badge>
                        )
                        :  getBookedFDStatusBadge(data.recentUserFd.status)
                      }
                      </div>
                  </CardHeader>
                  {/* <CardHeader className="justify-between w-full flex-row">
                      <div className="flex space-x-2 align-center">
                          <div className="p-1 border shadow-sm rounded-lg">
                              <img
                              src={getFdImgSrc(data.recentUserFd.fdImageId)}
                              alt={data.recentUserFd.fdName}
                              className="h-10 w-10 object-cover rounded-full"
                              />
                          </div>
                          <div className="px-1 md:px-2 py-1 lg:px-2 self-center">
                              <div>{getMaxCharName(data.recentUserFd.fdName, 22)}</div>
                              <p className="text-xs text-muted-foreground">
                                  {new Date(data.recentUserFd.createdAt).toLocaleString('en-In', {
                                      timeZone: 'Asia/Kolkata',
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric"
                                  })}
                              </p>
                          </div>
                      </div>
              
                      <div className="self-center">
                      {
                        data.recentUserFd.status === 'session-started'
                        ? (
                          <Badge variant="fd_pending" className="text-xs px-2 lg:px-3">{getSFBStatus(data.recentUserFd.fdSession?.step, data.recentUserFd.fdSession?.status) }</Badge>
                        )
                        :  getBookedFDStatusBadge(data.recentUserFd.status)
                      }
                      </div>
                  </CardHeader> */}
                  <Separator className="h-[2px]"  />
                  <CardContent className="p-6 xs:p-6 xs:pt-8 flex justify-around space-x-4">
                      <div>
                          <div className="">
                              <div className="text-muted-foreground text-sm">Amount</div>
                              <div>&#8377; {new Intl.NumberFormat('en-In').format(Number(data.recentUserFd.amount))}</div>
                          </div>
                      </div>
                      <Separator orientation='vertical' />
                      <div>
                          <div className="">
                              <div className="text-muted-foreground text-sm">Tenure</div>
                              <div>{data.recentUserFd.tenureYearFormat}</div>
                          </div>
                      </div>
                      <Separator orientation='vertical' />
                      <div>
                          <div className="">
                              <div className="text-muted-foreground text-sm">Interest Rate</div>
                              <div>{data.recentUserFd.interest}%</div>
                          </div>
                      </div>
                  </CardContent>
                  </Card>
                </NavLink> 
          </div>
          </div>
          </>
        )
      }

 

      <div id="listFds" className="w-full mb-20 lg:mb-40 grid gap-4 md:gap-10 md:grid-cols-2 lg:grid-cols-2 justify-items-center">
       {
        data.fds.map(fd => (
          
          <div 
            className="w-[calc(100vw-3rem)] sm:w-full md:min-w-[70%] max-w-md"
            key={fd.id}
          >
            <Link to={`/fd/${fd.id}`}>
            {
              // fd.name.split(' ')[0] === 'Suryoday' ? (
              //   <div className="relative w-[95%] xs:w-full h-full overflow-hidden rounded-xl border bg-background md:shadow-xl">
              //     <BorderBeam size={250} duration={12} delay={9} />
              //     <div
              //     >
              //       <CardHeader className="relative p-2 xs:p-6 lg:static space-y-1 justify-between w-full flex-row">
              //         <div className="flex space-x-4 align-center">
              //           <div className="p-2 border rounded-xl shadow-sm">
              //             <img
              //               src={getFdImgSrc(fd.logoImage?.id ?? null)}
              //               alt={fd.logoImage?.altText ?? "logo img"}
              //               className="h-8 w-8 xs:h-12 xs:w-12 object-cover rounded-full"
              //             />
              //           </div>
              //           <div className="p-0.5 xs:p-2 self-center">{fd.name}</div>
              //         </div>

              //         <div className="self-end absolute right-0 top-0.5 rounded-none">
              //           { fd.isRbiInsured ? (<Badge variant={"max_return"} className="text-xs">upto &#8377;5L Insured </Badge>) : null}
              //         </div>

              //       </CardHeader>
              //       <CardContent className="p-2 xs:p-6 flex justify-between pt-6 xs:pt-8">
              //         <div>
              //           <div className="text-xs text-muted-foreground">up to</div>
              //           <div className="">
              //             <span className="text-3xl font-semibold text-gain">{fd.highestInterest}% <span className="text-sm font-light text-muted-foreground">p.a.</span></span>
              //           </div>
              //         </div>
              //         <div className="self-center">

              //           {
              //             fd.name.includes('Axis') 
              //               ? (
              //               <Button asChild>
              //               <Link to={`/fd/${fd.id}`}>Coming soon</Link>
              //               </Button>
              //               ) : (
              //               <Button asChild>
              //               <Link to={`/fd/${fd.id}`}>Book now</Link>
              //               </Button>
              //               )
              //           }
                    
              //         </div>
              //       </CardContent>
              //     </div>
              //   </div>
              // ) : (
            <FeatureCard 
            key={fd.id} 
            title='title' 
            description='desc'
            >
              <CardHeader className="relative p-6 xs:p-6 lg:static space-y-1 justify-between w-full flex-row">
                <div className="flex space-x-2 md:space-x-4 align-center">
                  <div className="p-2 border rounded-xl shadow-sm">
                    <img
                      src={getFdImgSrc(fd.logoImage?.id ?? null)}
                      alt={fd.logoImage?.altText ?? "logo img"}
                      className="h-8 w-8 xs:h-12 xs:w-12 object-cover rounded-full"
                    />
                  </div>
                  <div className="p-0.5 xs:p-2 self-center">{fd.name}</div>
                </div>

                <div className="self-end absolute right-0 top-0.5 rounded-none">
                  { fd.isRbiInsured ? (<Badge variant={"max_return"} className="text-xs">Up to &#8377;5L insured </Badge>) : null}
                </div>
          
                {/* <div className="hidden lg:block self-center">
                  { fd.isRbiInsured ? (<Badge variant="outline" className="text-xs">upto &#8377;5L Insured </Badge>) : null}
                </div> */}
              </CardHeader>
              <CardContent className="p-6 xs:p-6 flex justify-between pt-6 xs:pt-8">
                <div>
                  <div className="text-xs text-muted-foreground">up to</div>
                  <div className="">
                    <span className="text-3xl font-semibold text-gain">{fd.highestInterest}% <span className="text-sm font-light text-muted-foreground">p.a.</span></span>
                  </div>
                </div>
                <div className="self-center">

                  {
                    fd.name.includes('Axis') 
                      ? (
                      <Button asChild>
                      <Link to={`/fd/${fd.id}`}>Coming soon</Link>
                      </Button>
                      ) : (
                      <Button asChild>
                      <Link to={`/fd/${fd.id}`}>Book now</Link>
                      </Button>
                      )
                  }
              
                </div>
              </CardContent>
            </FeatureCard>
              // )
            }
            
          </Link>
        </div>
      
        ))
       }

      </div>

      {/* <section className="w-full py-12 md:py-24 lg:py-32">
      <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6 lg:gap-10">
        <div className="space-y-3">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Testimonials</h2>
          <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
            Don't just take our word for it. Hear what our customers have to say.
          </p>
        </div>
        <div className="grid gap-6 md:gap-12 lg:grid-cols-3">
          <Card>
            <CardContent className="flex flex-col items-center space-y-4 p-6">
              <Avatar>
                <AvatarImage alt="Person 1" src="/placeholder-avatar.jpg" />
                <AvatarFallback>P1</AvatarFallback>
              </Avatar>
              <div className="text-center">
                <h3 className="font-semibold">Person 1</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">Company 1</p>
              </div>
              <blockquote className="text-lg font-semibold leading-snug lg:text-xl lg:leading-normal xl:text-2xl">
                “This is a testimonial from Person 1.“
              </blockquote>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="flex flex-col items-center space-y-4 p-6">
              <Avatar>
                <AvatarImage alt="Person 2" src="/placeholder-avatar.jpg" />
                <AvatarFallback>P2</AvatarFallback>
              </Avatar>
              <div className="text-center">
                <h3 className="font-semibold">Person 2</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">Company 2</p>
              </div>
              <blockquote className="text-lg font-semibold leading-snug lg:text-xl lg:leading-normal xl:text-2xl">
                “This is a testimonial from Person 2.“
              </blockquote>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="flex flex-col items-center space-y-4 p-6">
              <Avatar>
                <AvatarImage alt="Person 3" src="/placeholder-avatar.jpg" />
                <AvatarFallback>P3</AvatarFallback>
              </Avatar>
              <div className="text-center">
                <h3 className="font-semibold">Person 3</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">Company 3</p>
              </div>
              <blockquote className="text-lg font-semibold leading-snug lg:text-xl lg:leading-normal xl:text-2xl">
                “This is a testimonial from Person 3.“
              </blockquote>
            </CardContent>
          </Card>
        </div>
      </div>
    </section> */}

    <section className="w-full py-6 md:py-24">
      <div className="container px-4 md:px-6">
        
        <h2 className="text-[1.75rem] md:text-[2.5rem] lg:text-6xl font-semibold tracking-tighter text-center mb-6">Super FD is the best platform to book FDs</h2>
        <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-8 text-center md:text-left">
          {/* <div className="md:w-1/2 space-y-2">
          <button className="bg-slate-800 animate-slidein [--slidein-delay:300ms] opacity-0 no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-full p-px text-xs font-semibold leading-6  text-white inline-block">
          <span className="absolute inset-0 overflow-hidden rounded-full">
            <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </span>
          <div className="relative flex space-x-2 items-center z-10 rounded-full py-0.5 px-4 ring-1 ring-white/10 ">
            <span>
              Introducing Super FD
            </span>
            <svg
              fill="none"
              height="16"
              viewBox="0 0 24 24"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.75 8.75L14.25 12L10.75 15.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </div>
          <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40" />
           </button>
            <h2 className="text-3xl font-semibold tracking-tighter sm:text-5xl">Introducing Super FD</h2>
            <p className="max-w-[700px] text-gray-500 md:text-md/relaxed lg:text-md/relaxed xl:text-lg/relaxed dark:text-gray-400">
            Super FD is the best platform to book FDs digitally and earn high interest rates upto 9.1% p.a. Super FD works only with RBI regulated Banks and NBFCs.
            </p>
          </div> */}

          
          <div className="">
          <div className="w-full flex items-center justify-center">
          {/* Core Loader Modal */}
           {/* <MultiStepLoader loadingStates={loadingStates} loading={true} duration={2000} /> */}
           <div className="flex relative justify-start text-lg md:text-xl max-w-xl mx-auto flex-col mt-4 md:mt-10">
              {loadingStates.map((loadingState, index) => {
  
                return (
                  <div
                    key={index}
                    className={cn("text-left flex gap-2 mb-4")}
                  >
                    <div>
                        {/* <CheckIcon className="" /> */}
                        <CheckFilled
                          className="text-gain dark:text-gain opacity-100"
                        />
                    </div>
                    <span
                      className={"opacity-80"}
                    >
                      {loadingState.text}
                    </span>
                  </div>
                );
              })}
           </div>
          </div>
          </div>
        </div>
      </div>
  </section>



  {/* <div className="pt-24">
    <section className="flex flex-col items-center justify-center text-center space-y-8">
      <h1 className="text-4xl font-extrabold">What others to say about Super FD
      </h1>
    </section>
    <div className="h-[40rem] rounded-md flex flex-col antialiased items-center justify-center relative overflow-hidden">
    <InfiniteMovingCards
      items={testimonials}
      direction="right"
      speed="slow"
    />
  </div>
  </div> */}

    <section className="py-24 lg:py-32">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-[1.75rem] md:text-[2.5rem] lg:text-6xl font-semibold tracking-tighter text-center">The right time to invest in FDs is <span className="text-gain">NOW</span></h2>
            <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Secure 9.1% interest rate before they drop
            </p>
            <div>
            <Link to={'#heroSection'} className="data-te-smooth-scroll-init animate-slidein [--slidein-delay:300ms] opacity-0 no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-full p-px text-xs font-semibold leading-6 text-white inline-block">
             <Button
             className="animate-shimmer text-[#0f172a] bg-[linear-gradient(110deg,#F8FAFC,45%,#22c55e,55%,#F8FAFC)] bg-[length:200%_100%] px-6 font-medium transition-colors"
             >Invest Now</Button>
            </Link>
            </div>
          </div>
          <div>
            <div className="py-10">
             <img
              src={InvestNowImg}
              alt="invest now"
              className="object-cover"
             />
            </div>
            {/* <div className="text-muted-foreground mt-10">
              2 year FD rates, as reported by <span className=""><a className="text-blue-600 underline hover:text-blue-800 visited:text-purple-600" href={'https://economictimes.indiatimes.com/wealth/invest/countdown-for-fd-rate-fall-has-started-last-window-to-invest-in-fixed-deposit-at-current-high-rates/articleshow/114065232.cms?from=mdr'} target='_blank' rel="noreferrer">Economic Times</a></span>
            </div> */}
          </div>
        </div>
        <div>

        </div>
      </div>
    </section>

    <div className="mt-4 lampcontainer -z-10 flex rotate-180 opacity-50 dark:opacity-100">
      <div
        className={clsx(
          { 'scale-[3] md:scale-[2] 2xl:scale-[1.55]': inView },
          'lamp translate-z-0 translate-y-[-180px] rotate-180 scale-50 animate-none duration-1000',
        )}
        ref={ref}
        style={
          {
            '--bottom': '#4188e6',
          } as WrapperStyle
        }
      />
    </div>

    <section className="py-12 lg:py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-[1.75rem] md:text-[2.5rem] lg:text-6xl font-semibold tracking-tighter text-center mb-6">One-stop platform to book and manage FDs</h2>
      </div>
        <div className="w-full flex items-center justify-center">
         <AnimatedSuperFDBeam />      
        </div>
    </section>

    {/* <section className="pt-24 pb-12 lg:pt-32 lg:pb-12">
       <div className="container mx-auto px-4">
        <div className="w-full flex items-center justify-center">
         <AnimatedSuperFDBeam />      
        </div>
      </div>  
    </section> */}

    {/* Trusted by 5,000+ investors */}
    <section id="joinTop1Percent"  className="pt-24 pb-12 lg:pt-32 lg:pb-12">
      <div className="container mx-auto px-4">
        <h2 className="text-[1.75rem] md:text-[2.5rem] lg:text-6xl font-semibold tracking-tighter text-center mb-6">Join our growing community of <span className="text-gain">Super Investors</span></h2>
      </div>
    </section>

    <section className="w-full pb-12 md:pb-24 lg:pb-32">
      <div className="container mx-auto px-4">
        <BentoGrid className="lg:grid-rows-3">
          {
            features.map(feature => (
              <BentoCard key={feature.name} {...feature} />
            ))
          }
        </BentoGrid>
      </div>
    </section>

    <section className="w-full relative pb-12 md:pb-24 lg:pb-32">

      <div className="w-full md:w-4/6 lg:w-1/2 mx-auto flex justify-center items-center space-x-4">
        <Separator className="hidden md:block md:w-[10%] lg:w-[20%]" />
        <div className="w-full md:w-[80%] lg:w-[60%] text-center text-xl font-semibold tracking-wider text-zinc-600 dark:text-zinc-400">
          Super FD is partnered with
        </div>
        <Separator className="hidden md:block md:w-[10%] lg:w-[20%]" />
      </div>

      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">

      <div className="relative w-full md:w-2/3 lg:w-2/5 mx-auto mt-12 grid gap-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
       <TiltCard>
        <img src={UnityFullImage} alt="unity issuer" />
       </TiltCard>
       <TiltCard>
        <img src={SuryodayFullImage} alt="suryoday issuer" />
       </TiltCard>
       <TiltCard>
        <img src={ShriramFullImage} alt="shriram issuer" />
       </TiltCard>
       <TiltCard className="md:hidden">
          <img src={BajajFullImage} alt="bajaj issuer" />
        </TiltCard>
        <TiltCard className="md:hidden">
          <img src={MahindraFullImage} alt="mahindra issuer" />
        </TiltCard>
       {/* <div className="col-span-2">
        <TiltCard className="w-1/2 mx-auto">
          <img src={MahindraFullImage} alt="suryoday issuer" />
        </TiltCard>
       </div> */}
       
      </div>

      <div className="relative w-1/2 hidden md:w-2/3 lg:w-[26%] mx-auto mt-12 md:grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
       <TiltCard>
        <img src={BajajFullImage} alt="bajaj issuer" />
       </TiltCard>
       <TiltCard>
        <img src={MahindraFullImage} alt="mahindra issuer"/>
       </TiltCard>
      </div>

      </div>
      <Blur />
      </section>

    {/* <div className="[--color:var(--color-one)] pointer-events-none relative -z-[2] mx-auto h-[45rem] overflow-hidden [mask-image:radial-gradient(ellipse_at_center_center,#000,transparent_50%)] my-[-18.8rem] before:absolute before:inset-0 before:h-full before:w-full before:opacity-40 before:[background-image:radial-gradient(circle_at_bottom_center,var(--color),transparent_70%)] after:absolute after:-left-1/2 after:top-1/2 after:aspect-[1/0.7] after:w-[200%] after:rounded-[50%] after:border-t after:border-[hsl(var(--border))] after:bg-background"></div>
     */}
    <section className="w-full py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4">
        <div className="">
          <div className="space-y-2 text-center">
            <h2 className="text-[1.75rem] md:text-[2.5rem] lg:text-6xl font-semibold tracking-tighter text-center">How to Invest using Super FD?</h2>
            <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Book FD in less than 5 minutes
            </p>
          </div>
          <div className="my-12">
            <div className="my-4 w-full">
              <Steps current={step} onChange={setNumber} steps={steps} />
            </div>
          </div>

          <div>

            {
              step === 0 && (
                <div className="w-full flex flex-col gap-8 md:justify-center md:flex-row lg:gap-12">
                  <div className="space-y-4 order-2 md:order-1 text-left lg:w-1/3">
                    <h3 className="text-2xl font-bold">Select FD</h3>
                    <p className="text-muted-foreground">
                     Select Tenure and Amount you like
                    </p>
                    <div className="flex flex-col items-start">
                      <ul className="space-y-2 text-muted-foreground flex flex-col items-baseline content-start">
                        <li className=''>
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Choose Tenure</span>
                          </div>
                        </li>
                        <li className="">
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Choose Amount</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                  <div className="w-full order-1 md:order-2 lg:w-2/3">
                   <ImageWindow className="h-[700px] md:hidden">
                    <img src={MobileStep1} alt="step 1 - choose fd" className="w-full h-full xl:h-[36.2rem] rounded-b-xl"/>
                   </ImageWindow>

                   <ImageWindow className="hidden md:block">
                    <img src={Step1} alt="step 1 - choose fd" className="w-full object-fill h-[13rem] md:h-[24rem] xl:h-[36.2rem] rounded-b-xl"/>
                   </ImageWindow>
        
                  </div>
                </div>
            
              )
            }

            {
              step === 1 && (
                <div className="w-full flex flex-col gap-8 md:justify-center md:flex-row lg:gap-12">
                  <div className="space-y-4 order-2 md:order-1 text-left lg:w-1/3">
                    <h3 className="text-2xl font-bold">KYC</h3>
                    <p className="text-muted-foreground">
                    Complete your KYC Details
                    </p>
                    <div className="flex flex-col items-start">
                      <ul className="space-y-2 text-muted-foreground flex flex-col items-baseline content-start">
                        <li className=''>
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>PAN No.</span>
                          </div>
                        </li>
                        <li className="">
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Aadhar No.</span>
                          </div>
                        </li>
                        <li className="">
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Personal Info</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                  <div className="w-full order-1 md:order-2 lg:w-2/3">
                  <ImageWindow className="h-[700px] md:hidden">
                    <img src={MobileStep2} alt="step 1 - choose fd" className="w-full h-full xl:h-[36.2rem] rounded-b-xl"/>
                   </ImageWindow>

                   <ImageWindow className="hidden md:block">
                    <img src={Step2} alt="step 2 - kyc" className="w-full object-fill h-[13rem] md:h-[24rem] xl:h-[36.2rem] rounded-b-xl"/>
                   </ImageWindow>
        
                  </div>
                </div>
            
              )
            }

            {
              step === 2 && (
                <div className="w-full flex flex-col gap-8 md:justify-center md:flex-row lg:gap-12">
                  <div className="space-y-4 order-2 md:order-1 text-left lg:w-1/3">
                    <h3 className="text-2xl font-bold">Review</h3>
                    <p className="text-muted-foreground">
                     Review your investment details
                    </p>
                    <div className="flex flex-col items-start">
                      <ul className="space-y-2 text-muted-foreground flex flex-col items-baseline content-start">
                        <li className=''>
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Check Tenure</span>
                          </div>
                        </li>
                        <li className="">
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Check Amount</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                  <div className="w-full order-1 md:order-2 lg:w-2/3">
                    <ImageWindow className="h-[700px] md:hidden">
                      <img src={MobileStep3} alt="step 1 - choose fd" className="w-full h-full xl:h-[36.2rem] rounded-b-xl"/>
                    </ImageWindow>

                    <ImageWindow className="hidden md:block">
                      <img src={Step3} alt="step 3 - review" className="w-full object-fill h-[13rem] md:h-[24rem] xl:h-[36.2rem] rounded-b-xl"/>
                    </ImageWindow>
        
                  </div>
                </div>
            
              )
            }

            {
              step === 3 && (
                <div className="w-full flex flex-col gap-8 md:justify-center md:flex-row lg:gap-12">
                  <div className="space-y-4 order-2 md:order-1vtext-left lg:w-1/3">
                    <h3 className="text-2xl font-bold">Payment</h3>
                    <p className="text-muted-foreground">
                     You can use UPI or Net Banking to invest
                    </p>
                    <div className="flex flex-col items-start">
                      <ul className="space-y-2 text-muted-foreground flex flex-col items-baseline content-start">
                        <li className=''>
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>UPI</span>
                          </div>
                        </li>
                        <li className="">
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Net Banking</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                  <div className="w-full order-1 md:order-2 lg:w-2/3">
                  <ImageWindow className="h-[700px] md:hidden">
                      <img src={MobileStep4} alt="step 1 - choose fd" className="w-full h-full xl:h-[36.2rem] rounded-b-xl"/>
                    </ImageWindow>

                    <ImageWindow className="hidden md:block">
                      <img src={Step4} alt="step 4 - payment" className="w-full h-[13rem] md:h-[24rem] xl:h-[34rem] rounded-b-xl"/>
                    </ImageWindow>
        
                  </div>
                </div>
            
              )
            }

            {
              step === 4 && (
                <div className="w-full flex flex-col gap-8 md:justify-center md:flex-row lg:gap-12">
                  <div className="space-y-4 order-2 md:order-1 text-left lg:w-1/3">
                    <h3 className="text-2xl font-bold">FD Receipt</h3>
                    <p className="text-muted-foreground">
                     Get FD receipt instantly from the Bank
                    </p>
                    <div className="flex flex-col items-start">
                      <ul className="space-y-2 text-muted-foreground flex flex-col items-baseline content-start">
                        <li className="">
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Instant FD Receipt</span>
                          </div>
                        </li>
                        <li className=''>
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>Email</span>
                          </div>
                        </li>
                        <li className=''>
                          <div className="flex gap-2">
                            <CheckIcon className="h-5 w-5 text-primary" />
                            <span>WhatsApp</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                  <div className="w-full order-1 md:order-2 lg:w-2/3">
                   <ImageWindow className="h-[700px] md:hidden">
                    <img src={MobileStep5} alt="step 5 - receipt" className="w-full h-full xl:h-[36.2rem] rounded-b-xl"/>
                    <CanvasConfetti
                      ref={confettiRef}
                      className="w-full absolute left-0 top-0 z-0 size-full"
                      onMouseEnter={() => {
                        confettiRef.current?.fire({});
                      }}
                    />
                   </ImageWindow>

                   <ImageWindow className="hidden md:block">
                    {/* <img src={Step5} alt="step 5 - receipt" className="w-full object-fill xl:h-[34rem] rounded-b-xl"/> */}
                    <img src={Step5} alt="step 5 - receipt" className="w-full object-fill h-[13rem] md:h-[24rem] xl:h-[34rem] rounded-b-xl"/>
                    <CanvasConfetti
                      ref={confettiRef}
                      className="w-full absolute left-0 top-0 z-0 size-full"
                      onMouseEnter={() => {
                        confettiRef.current?.fire({});
                      }}
                    />
                   </ImageWindow>
        
                  </div>
                </div>
            
              )
            }
             
          </div>

        </div>
      </div>
    </section>

     <section className="py-24 lg:py-32">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-[1.75rem] md:text-[2.5rem] lg:text-6xl font-semibold tracking-tighter text-center">What Our Customers Say</h2>
            <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Our customers ❤️ Super FD and can't stop talking about us
            </p>
          </div>
        </div>
        <div className="mt-16 w-[90%] md:w-full flex items-center justify-center">
        <Carousel className="w-full max-w-xs md:max-w-md lg:max-w-none" opts={{ align: "start", loop: true}}>
          <CarouselContent className="-ml-1">
            {
              testimonialsData.map(item => (
               <CarouselItem key={item.id} className="md:basis-1/2 lg:basis-1/3">
                 <Card className="flex h-full flex-col items-center justify-between gap-4 p-6 text-center">
                   <div className="text-xl font-bold">
                     {item.heading}
                   </div>
                   <p className="py-4 text-lg font-light leading-relaxed ">
                    {`"${item.quote}"`}
                   </p>
                   <div className="space-y-1">
                     <div className="font-semibold">{item.name}</div>
                     <div className="text-sm text-muted-foreground">{item.jobTitle}</div>
                   </div>
                 </Card>
                  {/* <Card className="h-full">
                  <CardContent className="flex flex-col justify-between h-full p-6 space-y-4">
                    <div>
                      <div className="flex items-center gap-2 mb-4">
                        <Avatar>
                          <AvatarImage alt="Avatar" src="/placeholder-avatar.jpg" />
                          <AvatarFallback>{item.avatarFallback}</AvatarFallback>
                        </Avatar>

                        <div>
                          <h4 className="font-semibold">{item.name}</h4>
                          <p className="text-sm text-gray-500 dark:text-gray-400">{item.jobTitle}</p>
                        </div>
                      </div>
                      <p className="text-lg leading-relaxed">
                        {`"${item.quote}"`}
                      </p>
                    </div>
                    {
                      item.star
                    }
                  </CardContent>
                </Card> */}
              </CarouselItem> 
              ))
            }
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
        </div>
      </div>
     </section>

     <section className="hidden lg:block py-24 lg:py-32">
     <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8">
          <div className="text-center md:text-left">
            <h2 className="text-4xl md:text-5xl font-bold mb-4">Super FD is just a tap away!</h2>
            <p className="text-xl md:text-2xl text-muted-foreground">Get the app on the Play Store today!</p>
          </div>

          <div className="flex flex-col sm:flex-row items-center gap-6">
           
            {/* <a href="https://play.google.com/store/apps/details?id=com.superfd.SuperFD&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" className="h-[60px] w-[200px]">
              <img
                src={GooglePlayImg}
                alt="Get it on Google Play"
                className="h-full w-full object-contain"
              />
            </a> */}

            {/* Google Play Store */}
            <div className="relative h-[60px] w-[180px]">
            <a href="https://play.google.com/store/apps/details?id=com.superfd.SuperFD&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" className="h-[60px] w-[180px]">
              <img
                src={GooglePlayImg}
                alt="Download on the App Store"
                className="h-full w-full object-contain"
              />
              </a>
            </div>

            {/* App Store */}
            <div className="relative h-[60px] w-[180px]">
              <img
                src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
                alt="Download on the App Store"
                className="h-full w-full object-contain opacity-50"
              />
              <div className="absolute inset-0 flex justify-end items-start">
                <span className="bg-white/90 text-black text-xs font-medium px-2 py-1 rounded">Coming Soon</span>
              </div>
            </div>
          </div>
        </div>
      </div>
     </section>

    <section className="w-full py-12 md:py-24 lg:py-32">
      <div className="container px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-2 lg:gap-12">
          <div className="flex flex-col justify-center space-y-4">
            <div className="space-y-2">
              <h2 className="text-3xl font-semibold tracking-tighter sm:text-5xl">
                FAQs
              </h2>
              <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                Frequently Asked Questions
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <Accordion className="w-full" collapsible type="single">
              {
                faqItems.map(faq => (
                  <AccordionItem key={faq.id} value={`item-${faq.id}`}>
                    
                    <BadgeAccordionTrigger className="text-left text-sm font-medium flex items-center">
        
                    <div className="min-w-[70px] md:min-w-[80px] mr-2">
                      {
                        FaqBadges(faq.type)
                      }
                    </div>
                    
                    <div>
                    {faq.question}
                    </div>

                    </BadgeAccordionTrigger>
                    <AccordionContent>
                      <div className="text-sm text-muted-foreground" dangerouslySetInnerHTML={{ __html: `${faq.answer}`}}/>
                    </AccordionContent>
                  </AccordionItem>
                ))
              }
            </Accordion>
          </div>
        </div>
      </div>
    </section>

      
      </PageContainer>
    // </PageBodyWithClassName>
  )

}

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex h-12 w-12 lg:h-20 lg:w-20 items-center justify-center rounded-full border-2 bg-white p-1 lg:p-3",
        className,
      )}
    >
      {children}
    </div>
  );
});

function AnimatedSuperFDBeam({
  className,
}: {
  className?: string;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const div6Ref = useRef<HTMLDivElement>(null);
  const div7Ref = useRef<HTMLDivElement>(null);
  
 
  return (
    <div
      className={cn(
        "relative flex w-full lg:max-w-[700px] items-center justify-center overflow-hidden bg-background p-10",
        className,
      )}
      ref={containerRef}
    >
      <div className="flex h-full w-full flex-row items-stretch justify-between gap-10">
        <div className="flex flex-col justify-center">
          <Circle ref={div7Ref}>
            <Icons.User />
          </Circle>
        </div>
        <div className="flex flex-col justify-center">
          <Circle ref={div6Ref} className="h-16 w-16 lg:h-20 lg:w-20">
            <Icons.SuperFD />
          </Circle>
        </div>
        <div className="flex flex-col justify-center gap-2">
          <Circle ref={div1Ref}>
            <Icons.Unity />
          </Circle>
          <Circle ref={div2Ref}>
            <Icons.Suryoday />
          </Circle>
          <Circle ref={div3Ref}>
            <Icons.Shriram />
          </Circle>
          <Circle ref={div4Ref}>
            <Icons.Bajaj />
          </Circle>
          <Circle ref={div5Ref}>
            <Icons.Mahindra />
          </Circle>
        </div>
      </div>
 
      {/* AnimatedBeams */}
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div6Ref}
        duration={3}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div6Ref}
        duration={3}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div6Ref}
        duration={3}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div6Ref}
        duration={3}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div5Ref}
        toRef={div6Ref}
        duration={3}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div7Ref}
        duration={3}
      />
    </div>
  );
}

type FAQTagType = 'safety' | 'booking' | 'reward' | 'support' | 'vkyc'

const faqItems: { id: number, type: FAQTagType, question: string, answer: string}[] = [
  {
    id: 1,
    type: 'safety',
    question: "Is it safe to invest in FDs on Super FD?",
    answer: `<div>We work only with <span style="font-weight: bold">RBI-regulated banking partners</span> and your money is always safe. Your money is directly transferred to the respective banks/NBFCs and you get a digital FD receipt from them instantly.</div>`
  },
  {
    id: 2,
    type: 'safety',
    question: "What is the RBI Insurance of 5 Lakhs?",
    answer: `
    <div>
    <div><span style="font-weight: bold">All Bank FDs are insured up to ₹5 Lakhs per depositor per bank</span>. It covers both the principal and the interest. This insurance is provided by the Deposit Insurance and Credit Guarantee Corporation, a wholly-owned <span style="font-weight: bold">subsidiary of RBI</span>, so the insurance will pay out even in case the bank goes down.</div>
    <div style="padding-top: 5px">You can read more at <a href="https://www.dicgc.org.in/FD_A-GuideToDepositInsurance.html" target="_blank" style="color: #3b82f6"">https://www.dicgc.org.in/FD_A-GuideToDepositInsurance.html</a></div>
    </div>
    `
  },
  {
    id: 3,
    type: 'booking',
    question: "How can I invest in 2 mins?",
    answer: `
    <div>
    <div>It’s a simple 3-step process:</div>
    <div style="padding-top: 5px">
     <ol style="padding-left: 5px">
      <li>1. Select an FD of your choice from our curated list</li>
      <li>2. Enter a few details for KYC, nominee details and bank account details for deposit of the maturity amount</li>
      <li>3. Make the payment</li>
     </ol>
    </div>
    <div style="padding-top: 5px">And you’re done! You’ll receive the FD receipt over email within one working day</div>
    <div style="padding-top: 5px;font-weight: bold">For repeat customers, FD booking takes literally one minute!</div>
    </div>
    `
  },
  {
    id: 4,
    type: 'booking',
    question: "Do I need to open a new savings account to book FD?",
    answer: "No new bank account is required. You can use your existing bank accounts to invest in any FD listed on Super FD and at maturity, the amount will similarly be credited back to an account of your choice."
  },
  {
    id: 5,
    type: 'reward',
    question: "How to avail the ₹250 Cashback?",
    answer: `
    <div>
    <div>You will get an assured Cashback of ₹250 when you book your first FD with <span style="font-weight: bold">Suryoday SF Bank.</span></div>
    <div style="padding-top: 5px">Book your first FD within 24 hours of sign up, and select the coupon <span style="font-weight: bold">SUP250</span> to avail the offer. The voucher code will be sent to your email and whatsapp within 15 days of booking.</div>
    </div>
    `
  },
]

export function FaqBadges(type: 'safety' | 'booking' | 'reward' | 'support' | 'vkyc'){
  switch(type){
    case 'safety': return (
      <OBadge className="bg-emerald-500/50 border-emerald-400 text-primary  gap-1.5">
        {/* <span className="h-1.5 w-1.5 rounded-full bg-emerald-500" aria-hidden="true"></span> */}
        Safety
      </OBadge> 
    )
    case 'booking': return (
      <OBadge className="bg-purple-500/50 border-purple-400 text-primary gap-1.5">
        {/* <span className="h-1.5 w-1.5 rounded-full bg-purple-500" aria-hidden="true"></span> */}
        Booking
      </OBadge> 
    )
    case 'reward': return (
      <OBadge className="bg-amber-500/50 border-amber-400 text-primary gap-1.5">
        {/* <span className="h-1.5 w-1.5 rounded-full bg-amber-500" aria-hidden="true"></span> */}
        Reward
      </OBadge> 
    )
    case 'support': return (
      <OBadge className="bg-blue-500/50 border-blue-400 text-primary gap-1.5">
        {/* <span className="h-1.5 w-1.5 rounded-full bg-blue-500" aria-hidden="true"></span> */}
        Support
      </OBadge> 
    )
    case 'vkyc': return (
      <OBadge className="bg-fuchsia-500/50 border-fuchsia-400 text-primary gap-1.5">
        {/* <span className="h-1.5 w-1.5 rounded-full bg-fuchsia-500" aria-hidden="true"></span> */}
        vKYC
      </OBadge> 
    )
    default: return (
      <OBadge className="bg-emerald-500/50 border-emerald-400 text-primary gap-1.5">
        {/* <span className="h-1.5 w-1.5 rounded-full bg-emerald-500" aria-hidden="true"></span> */}
        Safety
      </OBadge> 
    )
  }
}

const loadingStates = [
  {
    text: "Get FD returns of up to 9.1% p.a.",
  },
  {
    text: "No new savings account required",
  },
  {
    text: "FDs are regulated by RBI",
  },
  {
    text: "Book digitally in 5 mins",
  },
  {
    text: "All Bank FDs are insured up to ₹5L by DICGC",
  }
];

function StarIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
  )
}

const testimonialsData: Array<{id: number,avatarFallback: string, name: string, jobTitle: string,heading: string, quote: string, star: any}> = [
  {
    id: 1,
    avatarFallback: 'JD',
    name: 'Akshay',
    jobTitle: 'IT Professional',
    heading: "Best User Experience",
    quote: "I was amazed at how easy it was to book a FD through Super FD. It’s great that I don’t have to open a new savings account",
    star: <div className="flex items-center gap-2">
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-muted stroke-muted-foreground" />
            <StarIcon className="w-5 h-5 fill-muted stroke-muted-foreground" />
            <span className="text-sm text-gray-500 dark:text-gray-400">4.2 out of 5</span>
          </div>
  },
  {
    id: 2,
    avatarFallback: 'JS',
    name: 'Siddharth',
    jobTitle: 'Stock Market Trader',
    heading: "Manage all FDs in one place",
    quote: "Super FD allows me to book and manage all my fixed deposits in one place. The high-yield FDs are a great way to maximise my returns.",
    star: <div className="flex items-center gap-2">
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-muted stroke-muted-foreground" />
            <span className="text-sm text-gray-500 dark:text-gray-400">4.8 out of 5</span>
          </div>
  },
  {
    id: 3,
    avatarFallback: 'MJ',
    name: 'Sneha',
    jobTitle: 'University Professor',
    heading: "Peace of Mind",
    quote: "All Bank FDs listed in Super FD are insured upto ₹5 Lakhs by RBI's DICGC, which gives me peace of mind. It ensures my money is safe.",
    star: <div className="flex items-center gap-2">
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <span className="text-sm text-gray-500 dark:text-gray-400">5.0 out of 5</span>
          </div>
  },
{
    id: 4,
    avatarFallback: 'SL',
    name: 'Harsha',
    jobTitle: 'Management Consultant',
    heading: "Booking in 5 minutes",
    quote: "Booking a fixed deposit has never been this quick! I was able to book my first FD in 5 minutes",
    star: <div className="flex items-center gap-2">
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-muted stroke-muted-foreground" />
            <span className="text-sm text-gray-500 dark:text-gray-400">4.6 out of 5</span>
          </div>
  },
  {
    id: 5,
    avatarFallback: 'SL',
    name: 'Suresh',
    jobTitle: 'Entrepreneur',
    heading: "High yield FDs from Multiple Banks",
    quote: "Finding high-yield fixed deposits has never been easier. Highly recommended for anyone looking to invest in FDs digitally",
    star: <div className="flex items-center gap-2">
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-primary" />
            <StarIcon className="w-5 h-5 fill-muted stroke-muted-foreground" />
            <span className="text-sm text-gray-500 dark:text-gray-400">4.6 out of 5</span>
          </div>
  },
]

const features: Array<{Icon: LucideIcon, name: string, description: string, href: string, cta: string, background: any, className: string}> = [
  {
    Icon: SparklesIcon,
    name: "Earn like the Top 1%",
    description: "99% of FDs in India earn less than 9% p.a.",
    href: "/",
    cta: "Learn more",
    background: <img className="absolute -right-20 -top-20 opacity-60" alt="bg-img" />,
    className: "lg:col-start-1 lg:col-end-2",
  },
  {
    Icon: GlobeIcon,
    name: "100% Digital",
    description: "Zero paperwork and 5 Min Booking",
    href: "/",
    cta: "Learn more",
    background: <img className="absolute -right-20 -top-20 opacity-60" alt="bg-img" />,
    className: "lg:col-start-2 lg:col-end-3 lg:row-start-1 lg:row-end-2",
  },
  {
    Icon: FingerprintIcon,
    name: "100% safe and secure",
    description: "Hand-picked Banking Partners",
    href: "/",
    cta: "Learn more",
    background: <img className="absolute -right-20 -top-20 opacity-60" alt="bg-img"/>,
    className: "lg:col-start-3 lg:col-end-4 lg:row-start-1 lg:row-end-2",
  },
  // {
  //   Icon: LineChartIcon,
  //   name: "FD returns are at an all time high",
  //   description: "Indian Banks offer FD returns ranging from 3.5% to 9.1% per annum.",
  //   href: "/",
  //   cta: "Learn more",
  //   background: <img className="absolute -right-20 -top-20 opacity-60" />,
  //   className: "lg:col-start-3 lg:col-end-3 lg:row-start-1 lg:row-end-2",
  // },
  // {
  //   Icon: PiggyBankIcon,
  //   name: "A portfolio must have",
  //   description:
  //     "35% of all Indian household's savings are in FDs.",
  //   href: "/",
  //   cta: "Learn more",
  //   background: <img className="absolute -right-20 -top-20 opacity-60" />,
  //   className: "lg:col-start-3 lg:col-end-3 lg:row-start-2 lg:row-end-4",
  // },
];

const Icons = {
  Shriram: () => (
    <img src={ShriramImg} alt="shriram" className="object-cover rounded-full" />
  ),
  SuperFD: () => (
    <img src={SuperFDImg} alt="super fd" className="object-cover rounded-full" />
  ),
  Bajaj: () => (
    <img src={BajajImg} alt="bajaj" className="object-cover rounded-full" />
  ),
  Mahindra: () => (
    <img src={MahindraImg} alt="mahindra" className="object-cover rounded-full" />
  ),
  Unity: () => (
    <img src={UnityImg} alt="unity" className="object-cover rounded-full" />
  ),
  Suryoday: () => (
    <img src={SuryodayImg} alt="suryoday" className="object-cover rounded-full" />
  ),
  User: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  ),
};

function useNumberCycler() {
  const [currentNumber, setCurrentNumber] = useState(0);
  const [dummy, setDummy] = useState(0);

  const increment = () => {
    setCurrentNumber((prevNumber) => {
      return (prevNumber + 1) % 5;
    });

    setDummy((prev) => prev + 1);
  };

  const setNumber = (stepNumber: number) => {
    setCurrentNumber(() => {
      return (stepNumber) % 5;
    });
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentNumber((prevNumber) => {
        return (prevNumber + 1) % 5;
      });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dummy]);

  return {
    increment,
    currentNumber,
    setNumber
  };
}

interface Props<T extends Step> {
  current: number;
  steps: T[];
  onChange: (index: number) => void;
}

interface Step {
  id: string;
  name: string;
  schema?: any;
}

function Steps<T extends Step>({ steps, current, onChange }: Props<T>) {

  return (
    <nav aria-label="Progress" className="flex justify-center px-4">
      <ol
        className="flex w-full flex-wrap items-start justify-start gap-2 divide-y sm:justify-center md:w-10/12 md:divide-y-0"
      >
        {steps.map((step, stepIdx) => {
          const isCompleted = current > stepIdx;
          const isCurrent = current === stepIdx;
          const isFuture = !isCompleted && !isCurrent;
          return (
            // z-50 makes it sit above navbar.tsx for pointer-events to work since the <nav> container is -mt-[56px]
            <li
              className={clsx(
                'relative z-10 rounded-full py-1 pl-[0.4rem] pr-3 transition-all duration-300 ease-in-out md:flex',
                isCompleted ? 'bg-emerald-500/20' : isCurrent ? 'bg-indigo-500/20' : 'bg-gray-500/10',
              )}
              key={step.name}
            >
              <div
                className={clsx(
                  'group flex w-full cursor-pointer items-center focus:outline-none focus-visible:ring-2',
                  '',
                )}
                onClick={() => onChange(stepIdx)}
              >
                <span className="flex items-center gap-2 text-sm font-medium">
                  <span
                    className={clsx(
                      'flex flex-shrink-0 items-center justify-center rounded-full duration-300',
                      isCompleted && 'h-4 w-4 bg-emerald-700 text-white dark:bg-emerald-400',
                      isCurrent && 'h-5 w-8 bg-violet-500/30 p-2 dark:bg-violet-500/50',
                      isFuture && 'h-5 w-5 bg-gray-500/10 p-2 dark:bg-gray-500/20',
                    )}
                  >
                    {isCompleted ? (
                      <CheckIcon
                        className="h-3 w-3 stroke-white stroke-[3] dark:stroke-black"
                        size={20}
                      />
                    ) : (
                      <span className={clsx('text-xs', !isCurrent && 'text-gray-500')}>
                        {stepIdx + 1}
                      </span>
                    )}
                  </span>
                  <span
                    className={clsx(
                      'text-sm font-medium duration-300',
                      isCompleted && 'text-emerald-700 dark:text-emerald-500',
                      isFuture && 'text-gray-500',
                      isCurrent && 'text-violet-700 dark:text-indigo-200'
                    )}
                  >
                    {step.name}
                  </span>
                </span>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

function Blur() {
  return (
    <div
      aria-hidden="true"
      className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20 pointer-events-none"
    >
      <div className="fix-safari-blur blur-[106px] h-56 bg-gradient-to-br from-violet-500 to-purple-400 dark:from-fuchsia-700"></div>
      <div className="fix-safari-blur blur-[106px] h-32 bg-gradient-to-r from-fuchsia-400 to-purple-300 dark:to-violet-600"></div>
    </div>
  );
}
